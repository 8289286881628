// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBWde5HI6nxEWEHj1FqLWJHEdjLoR-eQYo",
  authDomain: "vedicchess-866c3.firebaseapp.com",
  projectId: "vedicchess-866c3",
  storageBucket: "vedicchess-866c3.appspot.com",
  messagingSenderId: "659023435630",
  appId: "1:659023435630:web:f0655031da66ece59d137f",
  measurementId: "G-NTNEZTC02Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth =  getAuth(app);

export {auth};