import React from 'react'
import GroupCourse from '../Components/GroupCourse.jsx'
import Curriculum from '../Components/Curriculum.jsx'


const Chess = () => {
  return (
    <>
      <Curriculum/>
      <GroupCourse/>
    </>
  )
}

export default Chess
