import React from 'react';
import './GroupCourse.css'; // Import a CSS file for styling

const GroupCourse = () => {
    return (
        <div className="group-course-container">
            <div className="course-card">
                <h3>Group Course</h3>
                <div className="course-duration">3 Months</div>
                <div className="course-sessions">24 live sessions</div>
                <div className="course-price">
                    <span className="discounted-price">$8000</span>
                    <span className="original-price">$ 8800</span>
                    <span className="price-per-session">$ 334 per session</span>
                </div>
                <button className="enroll-button">ENROLL</button>
            </div>
            <div className="course-card">
                <h3>Group Course</h3>
                <div className="course-duration">6 Months</div>
                <div className="course-sessions">48 live sessions</div>
                <div className="course-price">
                    <span className="discounted-price">$14400</span>
                    <span className="original-price">$ 15840</span>
                    <span className="price-per-session">$ 300 per session</span>
                </div>
                <button className="enroll-button">ENROLL</button>
            </div>
            <div className="course-card">
                <h3>Group Course</h3>
                <div className="course-duration">12 Months</div>
                <div className="course-sessions">96 live sessions</div>
                <div className="course-price">
                    <span className="discounted-price">$24000</span>
                    <span className="original-price">$ 26400</span>
                    <span className="price-per-session">$ 250 per session</span>
                </div>
                <button className="enroll-button">ENROLL</button>
            </div>
        </div>
    );
};

export default GroupCourse;