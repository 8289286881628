import React from "react";
import GroupCourse from "../Components/GroupCourse";
import Curriculum from "../Components/Curriculum";

const Vedic = () => {
  return (
    <div>
      <Curriculum />
      <GroupCourse />
    </div>
  );
};

export default Vedic;
