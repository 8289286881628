import React from "react";
import "./Details.css"
import chessImage from "../Components/vedic.png"

const Details = () => {
  return (
    <div className="form-container" style={{ backgroundImage: `url(${chessImage})`}}>
    <div className="outer-form">
      <form action="post" className="form-details">
        <label htmlFor="name" className="namelabel">
          Name:
        </label>{" "}
        <input type="text" className="name" />
        <br />
        <label htmlFor="name" className="namelabel">
          Email:
        </label>{" "}
        <input type="email" className="email" />
        <br />
        <label htmlFor="name" className="namelabel">
          Child's Name:
        </label>{" "}
        <input type="text" className="cname" />
        <br />
        <label htmlFor="name" className="namelabel">
          Child's Age:
        </label>{" "}
        <input type="number" className="cage" />
        <br />
        <label htmlFor="name" className="namelabel">
          Phone No:
        </label>{" "}
        <input type="tel" className="tel" />
        <br />
        <input type="submit" className="submit" />
      </form>
      </div>
    </div>
  );
};

export default Details;
