import React from 'react';
import './Mainsection.css';
import ches from "./ches.jpg"
import vedic from "./vedicmath.jpg"
import { useNavigate } from 'react-router-dom';


function Mainsection() {
  const navigate = useNavigate();
  const onChessImageClicked = (event) => {
    navigate('VCA.pdf');
  }
  const onVedicImageClicked = (event) => {
    navigate('VCA.pdf');
  }
  return (
    <div className="main-section">
      <div className="section">
        <img src={ches} alt="Events" onClick={onChessImageClicked}/>
        <div className="text-content">
          <h2 className='chess-txt'>Chess</h2>
          <p className='chess-desc'>Strengthening the child's mind from a young age!</p>
        </div>
      </div>
      <div className="section">
        <img src={vedic} alt="Explore" onClick={onVedicImageClicked}/>
        <div className="text-content">
          <h2 className='vedic-txt'>Vedic Maths</h2>
          <p className='vedic-desc'>Nurturing Young Minds for Lifelong Success</p>
        </div>
      </div>
    </div>
  );
}

export default Mainsection;
