import React from "react";
import "./Grid.css";
import ladder from "./ladder.jpeg";
import tutor from "./tutor.jpeg";
import time from "./time.jpeg";
import refund from "./refund.png";

function Grid() {
  return (
    <div className="outer-grid">
      <div className="lefter">
        <div className="left">
          <div className="left-row">
            <div className="left-img">
              <img src={ladder} className="ladder-img" alt="Chess" />
            </div>
          </div>
          <div className="left-desc">
            Structured Curriculum: Designed by Professional
          </div>
        </div>
        <div className="right">
          <div className="right-row">
            <div className="right-img">
              <img src={tutor} className="vedic-img" alt="Vedic" />
            </div>
          </div>
          <div className="right-desc">
            Top-Qualified Tutors: Certified Tutors
          </div>
        </div>
      </div>
      <div className="righter">
        <div className="left">
          <div className="left-row">
            <div className="left-img">
              <img src={time} className="ladder-img" alt="Chess" />
            </div>
          </div>
          <div className="left-desc">
            Flexible Scheduling: <br /> as per the schedule <br /> of the child
          </div>
        </div>
        <div className="right">
          <div className="right-row">
            <div className="right-img">
              <img src={refund} className="vedic-img" alt="Vedic" />
            </div>
          </div>
          <div className="right-desc">
            Refund Policy: <br />
            No questions asked
          </div>
        </div>
      </div>
    </div>
  );
}

export default Grid;
