import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import test1 from "./test1.png";
import test2 from "./test2.png";

const Testimonials = () => {
        return (
            <Carousel>
                <div className='Tuser'>
                    <img src={test1} alt='test1'/>  
                </div>
                <div className='Tuser'>
                    <img src={test2} alt='test2'/>    
                </div>
            </Carousel>
        );
    }

export default Testimonials