import React from 'react'
import "./Title.css"

function Title(props) {
  return (
    <div className='outer-title'>
      <p className='ptitle'>{props.Title}</p>
    </div>
  )
}

export default Title
