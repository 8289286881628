import React, { useState } from 'react';
import './Navbar.css';
import logo from './LOGO.png'; // Adjust the path to your logo image
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [isPlanVisitOpen, setPlanVisitOpen] = useState(false);
  const [isAboutOpen, setAboutOpen] = useState(false);
  const navigate = useNavigate();

  // const toggleDropdown = (dropdown) => {
  //   if (dropdown === 'planVisit') {
  //     setPlanVisitOpen(!isPlanVisitOpen);
  //     setAboutOpen(false);
  //   } else if (dropdown === 'about') {
  //     setAboutOpen(!isAboutOpen);
  //     setPlanVisitOpen(false);
  //   }
  // };
  const onLogoClicked = (event) => {
    // Assuming you want to redirect to the home page
    navigate('/');
  }
  const onChessClicked = (event) => {
    navigate('/chess');
  }
  const onVedicClicked = (event) => {
    navigate('/vedic');
  }
  

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <img src={logo} alt="Logo" className="logo" onClick={onLogoClicked}/>
      </div>
      <div className="navbar-right">
        <ul className="navbar-menu">
          <li className="navbar-item" onMouseEnter={() => setPlanVisitOpen(true)} onMouseLeave={() => setPlanVisitOpen(false)}>
            <span className='plan'>Plan Your Visit</span>
            {isPlanVisitOpen && (
              <div className="dropdown">
                <ul>
                  <li onClick={onChessClicked}>Chess</li>
                  <li onClick={onVedicClicked}>Vedic Maths</li>
                </ul>
              </div>
            )}
          </li>
          <li className="navbar-item" onMouseEnter={() => setAboutOpen(true)} onMouseLeave={() => setAboutOpen(false)}>
            <span className='ab'>About</span>
            {isAboutOpen && (
              <div className="dropdown">
                <ul>
                  <li onClick={onChessClicked}>Chess</li>
                  <li onClick={onVedicClicked}>Vedic Maths</li>
                </ul>
              </div>
            )}
          </li>
        </ul>
        <div className="navbar-actions">
          <a href='login' className="tickets-button">Login/Sign up</a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;