import React from "react";
import "./Moving.css";
import Marquee from "react-fast-marquee";
import Grid from "./Grid";




export default function Moving() {
  return (
    <div className="marquee">
      <Marquee className="marquee-text2">New batch starting from 25th June. Enroll Now 30% off</Marquee>
      <div className="vedic-text">Why Vedic Chess Academy?</div>
      <div className="marquee-abtext">
        <Grid />
      </div>
      <div className="company">
        <div className="years">
          <div className="service-no">25+</div>
          <div className="service-desc">Globally Available</div>
        </div>
        <div className="years">
          <div className="exp-no">12+</div>
          <div className="exp-desc">Years Of Experience</div>
        </div>
        <div className="years">
          <div className="coaches-no">65+</div>
          <div className="coaches-desc">Active Coaches</div>
        </div>
      </div>
      <Marquee className="marquee-text2">New batch starting from 25th June. Enroll Now 30% off</Marquee>
    </div>
  );
}
