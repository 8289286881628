import React from "react";
import "./Banner.css";


function Banner() {
  return (
    <>
      <div className="container">
        <div className="live">
          <h1 className="h1">
            <span className="HWDefine"> Live Online</span>
            <span className="highlight-color HWDefine">Extra-Curricular</span>
            <span className="HWDefine"> Courses</span>
          </h1>
          <div className="bookSessionCenter">
            <a href="https://forms.gle/3PHHUtaiaKDNYtWk6" className="btn btn-default">
              Book A Free Demo
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
