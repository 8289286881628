// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './Pages/Home';
import Navbar from './Components/Navbar';
// import { Form } from 'react-bootstrap';
import Details from './Pages/Details';
import Login from './Pages/Login';
import Chess from './Pages/Chess';
import Vedic from './Pages/Vedic';


function App() {
  return (
    <BrowserRouter>
          <div className="App">
            <Navbar />
              <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/login" element={<Login/>} />
                <Route path="/details" element={<Details />} />
                <Route path="/chess" element={<Chess />} />
                <Route path="/vedic" element={<Vedic />} />
              </Routes>
          </div>
    </BrowserRouter>
  );
}

export default App;
