import React, { useState } from "react";
import "./Login.css";
import OtpInput from "otp-input-react";
import { CgSpinner } from "react-icons/cg";
import { BsTelephoneFill } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';

function Login() {
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);

  const navigate = useNavigate();


  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();

    const appverifier = window.recaptchaVerifier;
    
    const formatPh = "+" + ph;
    signInWithPhoneNumber(auth, formatPh, appverifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sended successfully!!");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  function onOTPVerify(){
    setLoading(true);
    window.confirmationResult
    .confirm(otp)
    .then(async (res) => {
      console.log(res);
      setUser(res.user);
      setLoading(false);
      navigate("/");
    }).catch(err=>{
      console.log(err);
      setLoading(false);
    })
  }

  return (
    <div className="app">
      <section className="login-container">
        <Toaster toastOptions={{ duration: 4000 }} />
        <div id="recaptcha-container"></div>
        {user ? (
          <h2 className="success">Login Success</h2>
        )  : (
          
          <div>
            <h2>Welcome Back!</h2>
            {showOTP ? (
              <div>
                <>
                  <label htmlFor="ph" className="">
                    Enter your OTP
                  </label>{" "}
                  <OtpInput
                    OTPLength={6}
                    value={otp}
                    onChange={setOtp}
                    otpType="number"
                    disabled={false}
                    autoFocus
                    className="otp"
                  ></OtpInput>
                  <button type="submit" onClick={onOTPVerify}>
                    {loading && 
                      <CgSpinner size={20} className="animate-spin" />
                    }
                    <span>Verify OTP</span>
                  </button>
                </>
              </div>
            ) : (
              <div>
                <>
                  <BsTelephoneFill
                    size={30}
                    className="phn-logo"
                  ></BsTelephoneFill>
                  <div className="input-field">
                    <label htmlFor="ph" className="phn-label">
                      <b>Verify your phone number</b>
                    </label>{" "}
                    <PhoneInput
                      country={"in"}
                      value={ph}
                      onChange={setPh}
                      className="phn"
                    />
                  </div>
                  <button type="submit" onClick={onSignup}>
                    {loading && 
                      <CgSpinner size={20} className="animate-spin" />
                    }
                    <span>Send Code via SMS</span>
                  </button>
                </>
              </div>
            )}
          </div>
        )}
      </section>
    </div>
  );
}

export default Login;
