import React from 'react'
import "./Home.css";
import Title from '../Components/Title';
import Banner from '../Components/Banner';
import Footer from '../Components/Footer';
import Moving from '../Components/Moving';
import Testimonials from '../Components/Testimonials';
import Mainsection from '../Components/Mainsection';
export default function Home() {
  return (
    <>
    <Banner/>
    <Moving/>
    <Title Title={"Our Most Popular Courses"}/>
    <Mainsection/>
    <Title Title={"Testimonials"}/>
    <Testimonials/>
    <Footer/>
    </>
  )
}
