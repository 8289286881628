import React from "react";
import "./Curriculum.css";

const Curriculum = () => {
  return (
    <div className="curriculum-course-container">
      <div className="curriculum-course-card">
        <h3 className="fd">Foundation Courses</h3>
        <div className="curriculum-course-duration">Learning Outcome</div>
        <div className="curriculum-course-sessions">
          Basic knowledge of Chess and ready to play with any adult
        </div>
        <div className="curriculum-course-key">Key Topics</div>
        <div className="curriculum-course-points">
          <span className="curriculum-fundamental">Fundamentals Of Chess</span>
          <span className="curriculum-attack">Attack And Defence</span>
          <span className="curriculum-check">
            Check, Checkmate And Stalemate
          </span>
        </div>
        <div className="curriculum-line"></div>
        <div className="curriculum-course-line-below">
          <span className="curriculum-session">24 Session</span>
          <span className="curriculum-assignment">16 Assignments</span>
          <span className="curriculum-plus">100 Plus Puzzles</span>
          <span className="curriculum-monthly">Monthly Leaderboard Score</span>
          <span className="curriculum-tourny">Tournaments</span>
          <span className="curriculum-assess">Assessment Sessions</span>
          <span className="curriculum-platform">24*7 Platform Access</span>
          <span className="curriculum-feedback">Regular Feedback</span>
          <span className="curriculum-ptm">Monthly PTM</span>
          <span className="curriculum-time">Duration : 3 Months</span>
        </div>
        <button className="curriculum-enroll-button">ENROLL</button>
      </div>
      <div className="curriculum-course-card">
        <h3 className="fd">Foundation Courses</h3>
        <div className="curriculum-course-duration">Learning Outcome</div>
        <div className="curriculum-course-sessions">
          Basic knowledge of Chess and ready to play with any adult
        </div>
        <div className="curriculum-course-key">Key Topics</div>
        <div className="curriculum-course-points">
          <span className="curriculum-fundamental">Fundamentals Of Chess</span>
          <span className="curriculum-attack">Attack And Defence</span>
          <span className="curriculum-check">
            Check, Checkmate And Stalemate
          </span>
        </div>
        <div className="curriculum-line"></div>
        <div className="curriculum-course-line-below">
          <span className="curriculum-session">24 Session</span>
          <span className="curriculum-assignment">16 Assignments</span>
          <span className="curriculum-plus">100 Plus Puzzles</span>
          <span className="curriculum-monthly">Monthly Leaderboard Score</span>
          <span className="curriculum-tourny">Tournaments</span>
          <span className="curriculum-assess">Assessment Sessions</span>
          <span className="curriculum-platform">24*7 Platform Access</span>
          <span className="curriculum-feedback">Regular Feedback</span>
          <span className="curriculum-ptm">Monthly PTM</span>
          <span className="curriculum-time">Duration : 3 Months</span>
        </div>
        <button className="curriculum-enroll-button">ENROLL</button>
      </div>
      <div className="curriculum-course-card">
        <h3 className="fd">Foundation Courses</h3>
        <div className="curriculum-course-duration">Learning Outcome</div>
        <div className="curriculum-course-sessions">
          Basic knowledge of Chess and ready to play with any adult
        </div>
        <div className="curriculum-course-key">Key Topics</div>
        <div className="curriculum-course-points">
          <span className="curriculum-fundamental">Fundamentals Of Chess</span>
          <span className="curriculum-attack">Attack And Defence</span>
          <span className="curriculum-check">
            Check, Checkmate And Stalemate
          </span>
        </div>
        <div className="curriculum-line"></div>
        <div className="curriculum-course-line-below">
          <span className="curriculum-session">24 Session</span>
          <span className="curriculum-assignment">16 Assignments</span>
          <span className="curriculum-plus">100 Plus Puzzles</span>
          <span className="curriculum-monthly">Monthly Leaderboard Score</span>
          <span className="curriculum-tourny">Tournaments</span>
          <span className="curriculum-assess">Assessment Sessions</span>
          <span className="curriculum-platform">24*7 Platform Access</span>
          <span className="curriculum-feedback">Regular Feedback</span>
          <span className="curriculum-ptm">Monthly PTM</span>
          <span className="curriculum-time">Duration : 3 Months</span>
        </div>
        <button className="curriculum-enroll-button">ENROLL</button>
      </div>
    </div>
  );
};

export default Curriculum;
