import React from "react";
import "./Footer.css";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="footer-section hours">
          <p>Today's Hours</p>
          <p>
            We're open from 10:00 am to 6:00 pm
          </p>
          <h2>Drop a <a href="/"> <u>mail</u></a>  for any query</h2> 
        </div>
        <div className="footer-section contact">
          <address>
            <strong>Zar Ln, Hyderabad, Telangana 500004</strong>
            <br />
            <a href="tel:+91 8976148990">+91 8976148990</a>
          </address>
        </div>
        <div className="footer-section links">
          <ul>
            <li>Courses</li>
            <li>About</li>
          </ul>
          <ul>
            <li>Contact Us</li>
            <li>Pricing</li>
          </ul>
        </div>
        <div className="footer-section newsletter">
          <p>
            Don't miss a thing! Get our newsletter and never miss a new
            installation, event, or exhibit!
          </p>
          <form>
            <input type="email" placeholder="email@example.com" />
            <button type="submit">Sign up</button>
          </form>
        </div>
      <div className="footer-bottom">
        <p>
          &copy; 2024 All Rights Reserved. <a href="/login">Terms & Conditions.</a>
        </p>
        <div className="social-icons">
          <a href="/login">
            <FaFacebookF />
          </a>
          <a href="/login">
            <FaInstagram />
          </a>
          <a href="/login">
            <FaTwitter />
          </a>
        </div>
      </div>
      </footer>
    </>
  );
}

export default Footer;
